<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("users.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("users.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <!-- <button
                class="btn btn-success btn-add mr-2 mb-1"
                @click="onImport"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Import User
              </button> -->
              <button class="btn btn-success btn-add mr-4 mb-1" @click="onAdd">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("users.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('users.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="hover focus"
              >
                <b-tabs content-class="mt-3">
                  <b-tab :title="$t('filter.role')">
                    <div class="filter-overflow">
                      <div
                        class="form-checkbox"
                        v-for="role in optionRole1"
                        :key="role.id"
                      >
                        <input
                          :id="role"
                          class="form-radio-item"
                          type="radio"
                          :value="role"
                          v-model="filterRole"
                        />
                        <label class="form-radio-label" :for="role">
                          {{ role }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterRole === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="TSS">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchTSS"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="tss in dataTSSS"
                        :key="tss.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :value="tss.id"
                          :id="tss.id"
                          v-model="filterTSS"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="tss.id"
                        >
                          {{ tss.name }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterTSS == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab :title="$t('filter.region')" @click="handleIsFilter">
                    <div class="form-group" style="min-width: 250px">
                      <custom-select
                        :placeholder="$t('users.placeholder.region')"
                        v-model="filterRegion"
                        :options="dataRegion"
                        label="region"
                        @select="handleFilterRegionSelect('region')"
                      ></custom-select>
                    </div>
                    <div class="form-group" style="min-width: 250px">
                      <custom-select
                        :placeholder="$t('users.placeholder.province')"
                        v-model="filterProv"
                        :options="dataProvinsi"
                        label="province"
                        @select="handleFilterRegionSelect('province')"
                      ></custom-select>
                    </div>
                    <div class="form-group" style="min-width: 250px">
                      <custom-select
                        :placeholder="$t('users.placeholder.district')"
                        v-model="filterKab"
                        :options="dataKabupaten"
                        label="kabupaten"
                      ></custom-select>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{
                        disabled: filterRegion === null
                        // filterProv === null ||
                        // filterKab === null
                      }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive style="overflow: hidden;">
              <b-thead class="bv-head">
                <b-tr>
                  <b-th v-for="hd in $t('users.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="7" />
              </b-tbody>
              <b-tbody v-else-if="this.totalData !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="(data, index) in dataUsers"
                  :key="data.id"
                >
                  <b-td>
                    <!-- <input
                      type="checkbox"
                      :value="data.id"
                      v-model="spIds"
                      @click="select"
                  /> -->
                    {{ ++index + (currPage - 1) * perPage }}
                  </b-td>
                  <b-td>
                    <b>{{ data.name }}</b>
                    <div>{{ data.email }}</div>
                  </b-td>
                  <b-td>{{ convertRoles(data.role) }}</b-td>
                  <b-td>{{ data.ugdn }}</b-td>
                  <b-td v-if="data.isActive">
                    <span class="badge badge-blue">
                      ACTIVE
                    </span></b-td
                  >
                  <b-td v-else
                    ><span class="badge badge-red">
                      INACTIVE
                    </span></b-td
                  >
                  <b-td>
                    <label class="switch">
                      <input
                        type="checkbox"
                        class="shortime-toggle"
                        :checked="data.isActive == '1'"
                        @change="publishUser(data.id, $event)"
                      />
                      <span class="slider round"></span>
                    </label>
                  </b-td>
                  <b-td>
                    <button
                      :id="`popover-1-${data.id}`"
                      class="btn btn-invc p-0"
                    >
                      <div class="action-wrapper font-12">
                        <i class="ri-more-2-fill ri-lg ri-mid"></i>
                      </div>
                    </button>
                    <b-popover
                      :target="`popover-1-${data.id}`"
                      placement="right"
                      triggers="focus"
                    >
                      <div class="action-wrapper font-12">
                        <table>
                          <tr class="pointer" @click="onDetail(data)">
                            <td style="padding: 4px">
                              <i
                                class="ri-eye-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.detail") }}</td>
                          </tr>
                          <tr class="pointer" @click="onEdit(data)">
                            <td style="padding: 4px">
                              <i
                                class="ri-edit-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.edit") }}</td>
                          </tr>
                          <tr class="pointer" @click="onDelete(data.id)">
                            <td style="padding: 4px">
                              <i
                                class="ri-delete-back-2-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.delete") }}</td>
                          </tr>
                        </table>
                      </div>
                    </b-popover>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <b-tr>
                  <b-td colspan="9">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="MODAL" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form @submit="submitNew" v-if="!isDetail && !isEdit">
          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("users.label.name") }}<span class="text-red"> *</span>
            </label>
            <input
              class="form-control"
              type="text"
              :placeholder="$t('users.placeholder.name')"
              v-model="formTambah.name"
              :class="{
                'invalid-form': $v.formTambah.name.$error
              }"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black"
              >{{ $t("users.label.ugdn")
              }}<span class="text-red"> *</span></label
            >
            <input
              class="form-control"
              type="number"
              :placeholder="$t('users.placeholder.ugdn')"
              v-model="formTambah.ugdn"
              :class="{
                'invalid-form': $v.formTambah.ugdn.$error
              }"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black"
              >{{ $t("users.label.phone") }}
              <span class="text-red"> *</span></label
            >
            <input
              class="form-control"
              type="number"
              :placeholder="$t('users.placeholder.phone')"
              v-model="formTambah.phone"
              :class="{
                'invalid-form': $v.formTambah.phone.$error
              }"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black"
              >{{ $t("users.label.email") }}
              <span class="text-red"> *</span></label
            >
            <input
              class="form-control"
              type="email"
              :placeholder="$t('users.placeholder.email')"
              v-model="formTambah.email"
              :class="{
                'invalid-form': $v.formTambah.email.$error
              }"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black"
              >{{ $t("users.label.role")
              }}<span class="text-red"> *</span></label
            >
            <custom-select
              v-if="!isDetail"
              :placeholder="$t('users.placeholder.role')"
              v-model="formTambah.role"
              :options="dataRole.map(keg => keg.id)"
              :custom-label="opt => dataRole.find(x => x.id === opt).name"
              :allow-empty="false"
              :disabled="isDisabled"
              :class="{
                'invalid-form invalid-input': $v.formTambah.role.$error
              }"
            ></custom-select>
          </div>
          <div v-for="fld in details" :key="fld.id">
            <hr class="mt-1" />
            <div class="form-group">
              <label class="ssp-16 text-black">{{
                $t("users.label.region")
              }}</label>
              <custom-select
                :placeholder="$t('users.placeholder.region')"
                v-model="fld.region_id"
                :options="dataRegion"
                label="region"
                :allow-empty="false"
                :disabled="fld.disabled"
              ></custom-select>
            </div>
            <div class="form-group">
              <label class="ssp-16 text-black">{{
                $t("users.label.province")
              }}</label>
              <custom-select
                :placeholder="$t('users.placeholder.province')"
                v-model="fld.province_id"
                :options="dataProvinsi"
                label="province"
                :allow-empty="false"
                :disabled="fld.disabled"
              ></custom-select>
            </div>
            <div class="form-group">
              <label class="ssp-16 text-black">{{
                $t("users.label.district")
              }}</label>
              <custom-select
                :placeholder="$t('users.placeholder.district')"
                v-model="fld.kabupaten_id"
                :options="dataKabupaten"
                label="kabupaten"
                track-by="id"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                :disabled="fld.disabled"
              ></custom-select>
            </div>
            <div class="form-group">
              <label class="ssp-16 text-black">{{
                $t("users.label.group")
              }}</label>
              <custom-select
                :placeholder="$t('users.placeholder.group')"
                v-model="fld.group_id"
                :options="dataGrup"
                label="group"
                :allow-empty="false"
                :disabled="fld.disabled"
              ></custom-select>
            </div>
            <div class="form-group" v-if="formTambah.role === 'SP'">
              <label class="ssp-16 text-black">{{
                $t("users.label.tss")
              }}</label>
              <custom-select
                :placeholder="$t('users.placeholder.tss')"
                v-model="fld.tss_id"
                :options="dataTSS"
                label="name"
                :allow-empty="false"
                :disabled="fld.disabled"
              ></custom-select>
            </div>
            {{ fld.tss_id }}
            <!-- <section class="d-flex align-items-center mb-3">
              <div class="btn btn-secondary" @click="saveTemporary(index)">
                {{ $t("global.save") }}
              </div>
              <div class="ml-2 small text-red">
                Simpan sebelum menambahkan data region lain
              </div>
            </section> -->
          </div>
          <div class="row" v-if="!isDetail">
            <div class="col-lg-5 pr-1">
              <a
                class="btn btn-success ssp-14 w-100 my-3 py-2"
                @click="addWilayah"
              >
                {{ $t("users.label.add_terr") }}
              </a>
            </div>
            <div class="col-lg">
              <button
                type="submit"
                class="btn btn-primary ssp-14 w-100 my-3 py-2"
              >
                {{ $t("global.save_btn") }}
              </button>
            </div>
          </div>
        </form>
        <form @submit="submitEdit" v-else-if="isDetail || isEdit">
          <div class="form-group">
            <label class="ssp-16 text-black">{{
              $t("users.label.name")
            }}</label>
            <input
              class="form-control"
              type="text"
              v-model="formTambah.name"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("users.label.ugdn") }}
            </label>
            <input
              class="form-control"
              type="text"
              v-model="formTambah.ugdn"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">{{
              $t("users.label.phone")
            }}</label>
            <input
              class="form-control"
              type="text"
              v-model="formTambah.phone"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("users.label.email") }}
            </label>
            <input
              class="form-control"
              type="text"
              v-model="formTambah.email"
              :disabled="isDisabled"
            />
          </div>
          <div class="form-group">
            <label class="ssp-16 text-black"
              >{{ $t("users.label.role")
              }}<span class="text-red"> *</span></label
            >
            <custom-select
              :placeholder="$t('users.placeholder.role')"
              v-model="formTambah.role"
              :options="dataRole.map(keg => keg.id)"
              :custom-label="opt => dataRole.find(x => x.id === opt).name"
              :allow-empty="false"
              :disabled="isDisabled"
              :class="{
                'invalid-form invalid-input': $v.formTambah.role.$error
              }"
            />
          </div>
          <hr class="mt-2 mb-2" v-if="formTambah.role === 'MT'" />
          <section v-if="formTambah.role === 'MT'">
            <div class="ssp-16 text-black mb-2">Wewenang</div>
            <div v-if="formTambah.approvePropos">
              <div>
                <input
                  id="can-approve"
                  class="form-checkbox-item"
                  type="checkbox"
                  @input="publishApprove(formTambah.id, $event)"
                  v-model="formTambah.approvePropos.canApprove"
                  :disabled="isDisabled"
                />
                <label class="form-checkbox-label" for="can-approve">
                  Approve
                </label>
              </div>
              <div>
                <input
                  id="can-process"
                  class="form-checkbox-item"
                  type="checkbox"
                  @input="publishProcess(formTambah.id, $event)"
                  v-model="formTambah.approvePropos.canProcess"
                  :disabled="isDisabled"
                />
                <label class="form-checkbox-label" for="can-approve">
                  Process
                </label>
              </div>
              <div>
                <input
                  id="can-deliver"
                  class="form-checkbox-item"
                  type="checkbox"
                  @input="publishDeliver(formTambah.id, $event)"
                  v-model="formTambah.approvePropos.canDeliver"
                  :disabled="isDisabled"
                />
                <label class="form-checkbox-label" for="can-approve">
                  Deliver
                </label>
              </div>
            </div>
            <div v-else>
              <div>
                <input
                  id="can-approve"
                  class="form-checkbox-item"
                  type="checkbox"
                  @input="publishApprove(formTambah.id, $event)"
                  v-model="authority.canApprove"
                  :disabled="isDisabled"
                />
                <label class="form-checkbox-label" for="can-approve">
                  Approve
                </label>
              </div>
              <div>
                <input
                  id="can-process"
                  class="form-checkbox-item"
                  type="checkbox"
                  @input="publishProcess(formTambah.id, $event)"
                  v-model="authority.canProcess"
                  :disabled="isDisabled"
                />
                <label class="form-checkbox-label" for="can-approve">
                  Process
                </label>
              </div>
              <div>
                <input
                  id="can-deliver"
                  class="form-checkbox-item"
                  type="checkbox"
                  @input="publishDeliver(formTambah.id, $event)"
                  v-model="authority.canDeliver"
                  :disabled="isDisabled"
                />
                <label class="form-checkbox-label" for="can-approve">
                  Deliver
                </label>
              </div>
            </div>
          </section>
          <hr class="mt-2 mb-2" />
          <div class="ssp-16 text-black mb-2">
            {{ $t("users.label.terr_detail") }}
          </div>
          <div
            class="user-detail mb-3"
            v-for="(det, index) in details"
            :key="det.id"
          >
            <hr class="dw-divider" />
            <div class="d-flex mb-2">
              <div class="ssp-16 text-black">
                {{ $t("users.label.terr") }} {{ ++index }}
              </div>
              <span
                v-if="isEdit"
                class="ml-2 btn-sm btn-red pointer"
                @click="hapusWilayah(index)"
              >
                {{ $t("global.delete_btn") }}
              </span>
            </div>
            <div class="form-group">
              <label class="ssp-16 text-black">{{
                $t("users.label.region")
              }}</label>
              <custom-select
                placeholder=""
                v-model="det.region_id"
                :options="dataRegion.map(reg => reg.id)"
                :custom-label="opt => dataRegion.find(x => x.id === opt).region"
                :disabled="isDisabled"
              ></custom-select>
            </div>
            <div class="form-group">
              <label class="ssp-16 text-black">{{
                $t("users.label.province")
              }}</label>
              <custom-select
                placeholder=""
                v-model="det.province_id"
                :options="dataProvinsi.map(prov => prov.id)"
                :custom-label="
                  opt => dataProvinsi.find(x => x.id === opt).province
                "
                :disabled="isDisabled"
              ></custom-select>
            </div>
            <div class="form-group">
              <label class="ssp-16 text-black">{{
                $t("users.label.district")
              }}</label>
              <custom-select
                placeholder=""
                v-model="det.kabupaten_id"
                :options="dataKabupaten"
                label="kabupaten"
                track-by="id"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                :disabled="isDetail"
              ></custom-select>
            </div>
            <div class="form-group">
              <label class="ssp-16 text-black">{{
                $t("users.label.group")
              }}</label>
              <custom-select
                placeholder=""
                v-model="det.group_id"
                :options="dataGrup.map(grp => grp.id)"
                :custom-label="opt => dataGrup.find(x => x.id === opt).group"
                :disabled="isDisabled"
              ></custom-select>
            </div>
            <div class="form-group" v-if="formTambah.role === 'SP'">
              <label class="ssp-16 text-black">{{
                $t("users.label.tss")
              }}</label>
              <custom-select
                placeholder=""
                v-model="det.tss_id"
                :options="dataTSS"
                label="name"
                :disabled="isDisabled"
              ></custom-select>
            </div>
            <hr class="dw-divider" />
          </div>
          <div class="row" v-if="!isDetail">
            <div class="col-lg-5 pr-1">
              <a
                class="btn btn-success ssp-14 w-100 my-3 py-2"
                @click="addWilayah"
              >
                {{ $t("users.label.add_terr") }}
              </a>
            </div>
            <div class="col-lg">
              <button
                type="submit"
                class="btn btn-primary ssp-14 w-100 my-3 py-2"
              >
                {{ $t("global.save_btn") }}
              </button>
            </div>
          </div>
        </form>
      </template>
    </b-modal>
    <b-modal id="modal-import" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">Import User</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form @submit="importSP">
          <div class="form-group">
            <label class="ssp-16 text-black">
              File Excel
              <span class="text-red"> *</span>
            </label>
            <b-form-file
              v-model="dataImport"
              :placeholder="$t('global.excel_pch')"
              drop-placeholder="Drop file here..."
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
            </b-form-file>
          </div>
          <div class="form-group mb-0">
            <button type="button" class="btn btn-success" @click="template">
              Template Excel
            </button>
          </div>
          <button type="submit" class="btn btn-primary ssp-14 w-100 my-3 py-2">
            Submit
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import _ from "lodash";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import JwtService from "@/core/services/jwt.service";
import { RoleType } from "@/graphql/enum.type.js";
import {
  Q_LIST_USER1,
  // Q_LIST_USER,
  M_PUBLISH_USER,
  M_EDIT_SP
} from "@/graphql/sa";
// import { deleteUser } from "@/graphql/mutations";

import Multiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
// import _ from "lodash";
import { Q_LIST_KABUPATEN, Q_LIST_USERSEARCH } from "@/graphql/queries";
import {
  Q_LIST_REGION,
  M_ADD_USER,
  Q_LIST_PROVINCE,
  Q_LIST_GROUP,
  DETAIL_USER,
  PUBLISH_APPROVE,
  PUBLISH_PROCESS,
  PUBLISH_DELIVER
} from "@/graphql/super_admin";
import * as XLSX from "xlsx";

export default {
  components: {
    Card,
    "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      loading: true,
      dataUsers: [],
      dataTSS: [],
      dataCountry: [],
      dataRegion: [],
      dataProvinsi: [],
      dataKabupaten: [],
      dataFilterSP: [],
      dataProvinsiSP: [],
      dataKabupatenSP: [],
      optionRole: [RoleType.TSS, RoleType.SP],
      optionRole1: [
        RoleType.CM,
        RoleType.ADVISOR,
        RoleType.NSM,
        RoleType.NMM,
        RoleType.MT,
        RoleType.SA,
        RoleType.RM,
        RoleType.AM,
        RoleType.TSS,
        RoleType.SP
      ],
      dataRole: [
        { id: "CM", name: "Country Head of Indonesia" },
        { id: "ADVISOR", name: "Advisor" },
        { id: "NSM", name: "National Sales Manager" },
        { id: "NMM", name: "Marketing Head of Indonesia" },
        { id: "MT", name: "Marketing Team" },
        { id: "SA", name: "Sales Admin" },
        { id: "RM", name: "Regional Manager" },
        { id: "AM", name: "Areal Manager" },
        { id: "TSS", name: "Territory Sales Supervisor" },
        { id: "SP", name: "Sales Promotor" }
      ],

      openDetail: false,
      detailField: [],

      detailUser: JwtService.getDetail(),
      countryID: [],
      regionID: [],
      tssIDS: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterRole: null,
      filterTSS: [],
      filterRegion: null,
      filterProv: null,
      filterKab: null,

      tssID: JwtService.getID(),

      modalTitle: null,
      isDisabled: null,
      isDetail: null,
      isAdd: null,
      isFilter: null,

      formTambah: {},
      details: [],

      selected: [],
      allSelected: false,
      spIds: [],
      isEdit: false,
      dataImport: null,
      dokumentasiName: null,

      dataTSSS: [],
      searchTSS: "",

      detailsUser: {},
      authority: {}
    };
  },
  apollo: {
    listUser: {
      query: () => Q_LIST_USER1,
      variables() {
        return {
          page: parseInt(this.currPage),
          limit: this.perPage,
          keyword: this.filterInput,
          role: this.filterRole,
          country_id: this.getCountryID(),
          region_id: this.filterRegion ? [this.filterRegion.id] : [],
          prov_id: this.filterProv ? [this.filterProv.id] : [],
          kab_id: this.filterKab ? [this.filterKab.id] : [],
          kec_id: [],
          kel_id: [],
          tss_id: this.filterTSS
        };
      },
      debounce: 500,
      fetchPolicy: "network-only",
      result({ data }) {
        this.dataUsers = data.listUser.users;
        this.totalPages = data.listUser.totalPage;
        this.totalData = data.listUser.total;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listRegion: {
      query: () => Q_LIST_REGION,
      variables() {
        return {
          page: 1,
          limit: 999
        };
      },
      result({ data }) {
        this.dataRegion = data.listRegion.regions.map(a => {
          return {
            id: parseInt(a.id),
            region: a.region
          };
        });
      }
    },
    listProvince: {
      query: () => Q_LIST_PROVINCE,
      variables() {
        return {
          page: 1,
          limit: 999,
          keyword: "",
          region_id: this.isFilter
            ? this.filterRegion?.id
            : this.details.length && this.isAdd > 0
            ? this.details[this.details.length - 1].region_id
              ? this.details[this.details.length - 1].region_id.id
              : ""
            : ""
        };
      },
      result({ data }) {
        this.dataProvinsi = data.listProvince.provinces;
      }
    },
    listKabupaten: {
      query: () => Q_LIST_KABUPATEN,
      variables() {
        return {
          keyword: "",
          province_id: this.isFilter
            ? this.filterProv?.id
            : this.details.length && this.isAdd > 0
            ? this.details[this.details.length - 1].province_id
              ? this.details[this.details.length - 1].province_id.id
              : ""
            : ""
        };
      },
      result({ data }) {
        this.dataKabupaten = data.listKabupaten.kabupatens;
      }
    },
    listTSS: {
      query: () => Q_LIST_USERSEARCH,
      variables() {
        return {
          keyword: this.searchTSS,
          role: this.optionRole[0],
          country_id: [],
          region_id: [],
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          tss_id: [],
          orderBy: "NAME",
          sortBy: "ASC"
        };
      },
      debounce: 500,
      update: data => data.listUser,
      result({ data }) {
        this.dataTSSS = data.listUser.users;
      }
    }
  },
  validations: {
    formTambah: {
      name: { required },
      email: { required },
      phone: { required },
      ugdn: {},
      role: { required }
    }
  },
  methods: {
    // saveTemporary(index) {
    //   this.details[index].disabled = true;
    // },
    handleFilterRegionSelect(type) {
      switch (type) {
        case "region":
          this.filterProv = null;
          break;
        case "province":
          this.filterKab = null;
          break;
        default:
          break;
      }
    },
    handleIsFilter() {
      this.isFilter = true;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    async listTSS() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_USER1,
          variables: {
            page: 1,
            limit: 9999999,
            keyword: "",
            role: this.optionRole[0],
            country_id: [],
            region_id: [],
            prov_id: [],
            kab_id: [],
            kec_id: [],
            kel_id: [],
            tss_id: []
          }
        });
        this.dataTSS = result.data.listUser.users;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async listGroup() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_GROUP
        });
        this.dataGrup = result.data.listGroup.groups;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    importSP(e) {
      e.preventDefault();
      let formData = new FormData();
      formData.append("file", this.dataImport);
      axios
        .post(`${process.env.VUE_APP_URL_GRAPHQL}/user/import`, formData, {
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response) {
            this.$bvModal.hide("modal-import");
            this.dataImport = null;
            this.$apollo.queries.listUser.refetch();
            this.toastAlert(this.$t("alert.import"));
          }
        })
        .catch(err => {
          this.errorAlert(err);
        });
    },
    async publishUser(id, event) {
      return new Promise((resolve, reject) => {
        const publishUser = this.$apollo.mutate({
          mutation: M_PUBLISH_USER,
          variables: {
            id: id,
            isActive: event.target.checked
          }
        });
        if (resolve) {
          resolve(publishUser);
        } else {
          reject(publishUser);
        }
      })
        .then(response => {
          if (response) {
            this.toastAlert(this.$t("alert.edit"));
            this.$apollo.queries.listUser.refetch();
          }
        })
        .catch(err => {
          this.errorAlert(err);
        });
    },
    addWilayah() {
      this.details.push({});
    },
    hapusWilayah(i) {
      this.details.splice(i - 1, 1);
    },
    async submitNew1(e) {
      e.preventDefault();
      this.formTambah.detail = [];
      this.details.map(a => {
        if (a.kabupaten_id) {
          a.kabupaten_id.map(b => {
            let ob = {};
            if (a.region_id) {
              ob.region_id = a.region_id.id;
            }
            if (a.province_id) {
              ob.province_id = a.province_id.id;
            }
            if (a.kabupaten_id) {
              ob.kabupaten_id = b.id;
            }
            if (a.group_id) {
              ob.group_id = a.group_id.id;
            }
            if (a.tss_id) {
              ob.tss_id = a.tss_id.id;
            }
            this.formTambah.detail.push(ob);
          });
        } else {
          let ob = {};
          if (a.region_id) {
            ob.region_id = a.region_id.id;
          }
          if (a.province_id) {
            ob.province_id = a.province_id.id;
          }
          if (a.group_id) {
            ob.group_id = a.group_id.id;
          }
          if (a.tss_id) {
            ob.tss_id = a.tss_id.id;
          }
          this.formTambah.detail.push(ob);
        }
      });
    },
    async submitNew(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.formTambah.detail = [];
        this.details.map(a => {
          if (a.kabupaten_id) {
            a.kabupaten_id.map(b => {
              let ob = {};
              ob.country_id = 1;
              if (a.region_id) {
                ob.region_id = a.region_id.id;
              }
              if (a.province_id) {
                ob.province_id = a.province_id.id;
              }
              if (a.kabupaten_id) {
                ob.kabupaten_id = b.id;
              }
              if (a.group_id) {
                ob.group_id = a.group_id.id;
              }
              if (a.tss_id) {
                ob.tss_id = a.tss_id.id;
              }
              this.formTambah.detail.push(ob);
            });
          } else {
            let ob = {};
            ob.country_id = 1;
            if (a.region_id) {
              ob.region_id = a.region_id.id;
            }
            if (a.province_id) {
              ob.province_id = a.province_id.id;
            }
            if (a.group_id) {
              ob.group_id = a.group_id.id;
            }
            if (a.tss_id) {
              ob.tss_id = a.tss_id.id;
            }
            this.formTambah.detail.push(ob);
          }
        });
        return new Promise((resolve, reject) => {
          const addUser = this.$apollo.mutate({
            mutation: M_ADD_USER,
            variables: this.formTambah
          });
          if (resolve) {
            resolve(addUser);
          } else {
            reject(addUser);
          }
        })
          .then(response => {
            if (response) {
              this.toastAlert(this.$t("alert.save"));
              this.$apollo.queries.listUser.refetch();
              this.$bvModal.hide("MODAL");
            }
          })
          .catch(err => {
            this.errorAlert(err);
          });
      }
    },
    async submitEdit(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.formTambah.detail = [];
        this.details.map(a => {
          if (a.kabupaten_id) {
            if (a.kabupaten_id.length > 0) {
              a.kabupaten_id.map(b => {
                let ob = {};
                ob.country_id = 1;
                if (a.region_id) {
                  ob.region_id = a.region_id;
                }
                if (a.province_id) {
                  ob.province_id = a.province_id;
                }
                if (a.kabupaten_id) {
                  ob.kabupaten_id = b.id;
                }
                if (a.group_id) {
                  ob.group_id = a.group_id;
                }
                if (a.tss_id) {
                  ob.tss_id = a.tss_id.id;
                }
                this.formTambah.detail.push(ob);
              });
            } else {
              let ob = {};
              ob.country_id = 1;
              if (a.region_id) {
                ob.region_id = a.region_id;
              }
              if (a.province_id) {
                ob.province_id = a.province_id;
              }
              if (a.group_id) {
                ob.group_id = a.group_id;
              }
              if (a.tss_id) {
                ob.tss_id = a.tss_id.id;
              }
              this.formTambah.detail.push(ob);
            }
          } else {
            let ob = {};
            ob.country_id = 1;
            if (a.region_id) {
              ob.region_id = a.region_id;
            }
            if (a.province_id) {
              ob.province_id = a.province_id;
            }
            if (a.group_id) {
              ob.group_id = a.group_id;
            }
            if (a.tss_id) {
              ob.tss_id = a.tss_id.id;
            }
            this.formTambah.detail.push(ob);
          }
        });
        return new Promise((resolve, reject) => {
          const editUser = this.$apollo.mutate({
            mutation: M_EDIT_SP,
            variables: this.formTambah
          });
          if (resolve) {
            resolve(editUser);
          } else {
            reject(editUser);
          }
        })
          .then(response => {
            if (response) {
              this.$apollo.queries.listUser.refetch();
              this.toastAlert(this.$t("alert.edit"));
              this.closeModal();
            }
          })
          .catch(err => {
            this.errorAlert(err);
          });
      }
    },
    closeModal() {
      this.resetFormTambah();
      this.$bvModal.hide("MODAL");
    },
    resetFormTambah() {
      this.$v.$reset();
      this.formTambah = {
        name: null,
        phone: null,
        email: null,
        role: null,
        ugdn: null,
        detail: []
      };
      this.detailField = [];
    },
    onImport() {
      this.$bvModal.show("modal-import");
    },
    onAdd() {
      this.modalTitle = this.$t("users.add");
      this.isDisabled = false;
      this.isDetail = false;
      this.isEdit = false;
      this.isHide = true;
      this.isAdd = true;
      this.isFilter = false;
      this.$bvModal.show("MODAL");
      this.resetFormTambah();
      this.resetForm();
      this.openDetail = false;
    },
    resetFilter() {
      this.filterRole = null;
      this.filterTSS = [];
      this.filterRegion = null;
      this.filterProv = null;
      this.filterKab = null;
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetForm() {
      this.formTambah = {};
      this.details = [];
    },
    async onDetail(data) {
      this.isAdd = false;
      this.isEdit = false;
      this.isDetail = true;
      this.isFilter = false;
      this.resetForm();
      try {
        const result = await this.$apollo.query({
          query: DETAIL_USER,
          variables: {
            id: data.id
          },
          fetchPolicy: "no-cache"
        });
        this.formTambah = result.data.detailUser;

        const kabGrouped = _(result.data.detailUser.detail)
          .groupBy("province_id")
          .map(function(kabs, key) {
            return {
              province_id: key,
              kabupatens: kabs[0].kabupaten
                ? kabs.map(b => {
                    return b.kabupaten;
                  })
                : []
            };
          })
          .value();

        const detail = [];
        result.data.detailUser.detail.map(a => {
          const ob = {};
          ob.id = a.id;
          if (a.country_id) {
            ob.country_id = parseInt(a.country_id);
          }
          if (a.region_id) {
            ob.region_id = parseInt(a.region_id);
          }
          if (a.province_id) {
            ob.province_id = a.province_id;
          }
          if (a.kabupaten_id) {
            ob.kabupaten_id = [];
          }
          if (a.group_id) {
            ob.group_id = a.group_id;
          }
          if (a.tss_id) {
            ob.tss_id = a.tss;
          }
          detail.push(ob);
        });

        if (kabGrouped[0].province_id != "null") {
          const res = [];
          detail.map(a => {
            kabGrouped.map(b => {
              if (b.province_id === a.province_id) {
                res.push({
                  country_id: 1,
                  region_id: a.region_id,
                  province_id: a.province_id,
                  kabupaten_id: b.kabupatens,
                  group_id: a.group_id,
                  tss_id: a.tss_id
                });
              }
            });
          });
          this.details = _.uniqBy(res, "province_id");
        } else {
          this.details = detail;
        }

        this.modalTitle = this.$t("users.detail");
        this.isDisabled = true;
        this.isDetail = true;
        this.$bvModal.show("MODAL");
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    async onEdit(data) {
      this.isAdd = false;
      this.isEdit = true;
      this.isDetail = false;
      this.isFilter = false;
      this.resetForm();
      try {
        const result = await this.$apollo.query({
          query: DETAIL_USER,
          variables: {
            id: data.id
          },
          fetchPolicy: "no-cache"
        });
        this.formTambah = result.data.detailUser;

        const kabGrouped = _(result.data.detailUser.detail)
          .groupBy("province_id")
          .map(function(kabs, key) {
            return {
              province_id: key,
              kabupatens: kabs[0].kabupaten
                ? kabs.map(b => {
                    return b.kabupaten;
                  })
                : []
            };
          })
          .value();

        const detail = [];
        result.data.detailUser.detail.map(a => {
          const ob = {};
          ob.id = a.id;
          if (a.country_id) {
            ob.country_id = parseInt(a.country_id);
          }
          if (a.region_id) {
            ob.region_id = parseInt(a.region_id);
          }
          if (a.province_id) {
            ob.province_id = a.province_id;
          }
          if (a.kabupaten_id) {
            ob.kabupaten_id = [];
          }
          if (a.group_id) {
            ob.group_id = a.group_id;
          }
          if (a.tss_id) {
            ob.tss_id = a.tss;
          }
          detail.push(ob);
        });

        if (kabGrouped[0].province_id != "null") {
          const res = [];
          detail.map(a => {
            kabGrouped.map(b => {
              if (b.province_id === a.province_id) {
                res.push({
                  country_id: 1,
                  region_id: a.region_id,
                  province_id: a.province_id,
                  kabupaten_id: b.kabupatens,
                  group_id: a.group_id,
                  tss_id: a.tss_id
                });
              }
            });
          });
          this.details = _.uniqBy(res, "province_id");
        } else {
          this.details = detail;
        }

        this.modalTitle = this.$t("users.edit");
        this.isDisabled = false;
        this.isDetail = false;
        this.isEdit = true;
        this.$bvModal.show("MODAL");
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    selectAll() {
      this.spIds = [];
      if (!this.allSelected) {
        for (let i in this.dataUsers) {
          this.spIds.push(this.dataUsers[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    async handleExport() {
      this.isLoading = true;
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_USER1,
          variables: {
            page: 1,
            limit: 9999999,
            keyword: this.filterInput,
            role: this.filterRole,
            country_id: this.getCountryID(),
            region_id: this.getRegionID(),
            prov_id: [],
            kab_id: [],
            kec_id: [],
            kel_id: [],
            tss_id: []
          }
        });
        const list = result.data.listUser.users;
        import("@/plugins/export-excel").then(excel => {
          const tHeader = ["Name", "UGDN", "Email", "Role"];
          const filterVal = ["name", "ugdn", "email", "role"];
          setTimeout(() => {
            list.map(a => {
              return a;
            });
            const data = this.formatJson(filterVal, list);
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: this.$route.name,
              autoWidth: true,
              bookType: "xlsx"
            });
          }, 1500);
          this.limit = 10;
        });
      } catch (e) {
        this.errorAlert(e);
      }
      this.isLoading = false;
      // this.currPage = 1;
      // setTimeout(() => {
      //   const filter = this.dataUsers.map(a => {
      //     return {
      //       Nama: a.name,
      //       Jabatan: a.role,
      //       UGDN: a.ugdn,
      //       Email: a.email,
      //       Phone: a.phone,
      //       "Status Aktif": a.isActive
      //     };
      //   });

      //   const data = XLSX.utils.json_to_sheet(filter);
      //   const wb = XLSX.utils.book_new();
      //   XLSX.utils.book_append_sheet(wb, data, "Data User");
      //   XLSX.writeFile(wb, "Users.xlsx");
      //   this.perPage = 10;
      // }, 2000);
    },
    template() {
      const wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet([{}]);
      const data1 = [["No", "UGDN", "Email", "Phone", "Name", "Role"]];
      XLSX.utils.sheet_add_aoa(ws1, data1, { origin: "A1" });
      ws1["!cols"] = [
        { width: 4 },
        { width: 10 },
        { width: 20 },
        { width: 14 },
        { width: 16 },
        { width: 10 }
      ];
      let ws2 = XLSX.utils.json_to_sheet([{}]);
      XLSX.utils.sheet_add_aoa(
        ws2,
        [
          [
            "No",
            "Email",
            "Country",
            "Region",
            "Provinsi",
            "Kabupaten",
            "Kecamatan",
            "Kelurahan",
            "TSS"
          ]
        ],
        { origin: "A1" }
      );
      ws2["!cols"] = [
        { width: 4 },
        { width: 20 },
        { width: 18 },
        { width: 18 },
        { width: 18 },
        { width: 18 },
        { width: 18 },
        { width: 18 }
      ];
      XLSX.utils.book_append_sheet(wb, ws1, `users`);
      XLSX.utils.book_append_sheet(wb, ws2, `detail_users`);
      XLSX.writeFile(wb, `Template-${this.$route.name}.xlsx`);
    },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          return new Promise((resolve, reject) => {
            const publishUser = this.$apollo.mutate({
              mutation: M_PUBLISH_USER,
              variables: {
                id: id,
                isActive: false
              }
            });
            if (resolve) {
              resolve(publishUser);
            } else {
              reject(publishUser);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.edit"));
                this.$apollo.queries.listUser.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
          // return new Promise((resolve, reject) => {
          //   const delUser = this.$apollo.mutate({
          //     mutation: deleteUser,
          //     variables: {
          //       id: id
          //     }
          //   });
          //   if (resolve) {
          //     resolve(delUser);
          //   } else {
          //     reject(delUser);
          //   }
          // })
          //   .then(response => {
          //     if (response) {
          //       this.toastAlert(this.$t("alert.delete"));
          //       this.$apollo.queries.listUser.refetch();
          //     }
          //   })
          //   .catch(err => {
          //     this.errorAlert(err);
          //   });
        }
      });
    },
    async publishApprove(id, event) {
      return new Promise((resolve, reject) => {
        const pubApprove = this.$apollo.mutate({
          mutation: PUBLISH_APPROVE,
          variables: {
            id: id,
            status: event.target.checked
          }
        });
        if (resolve) {
          resolve(pubApprove);
        } else {
          reject(pubApprove);
        }
      })
        .then(response => {
          if (response) {
            this.toastAlert(this.$t("alert.edit"));
          }
        })
        .catch(err => {
          this.errorAlert(err);
        });
    },
    async publishProcess(id, event) {
      return new Promise((resolve, reject) => {
        const pubApprove = this.$apollo.mutate({
          mutation: PUBLISH_PROCESS,
          variables: {
            id: id,
            status: event.target.checked
          }
        });
        if (resolve) {
          resolve(pubApprove);
        } else {
          reject(pubApprove);
        }
      })
        .then(response => {
          if (response) {
            this.toastAlert(this.$t("alert.edit"));
          }
        })
        .catch(err => {
          this.errorAlert(err);
        });
    },
    async publishDeliver(id, event) {
      return new Promise((resolve, reject) => {
        const pubApprove = this.$apollo.mutate({
          mutation: PUBLISH_DELIVER,
          variables: {
            id: id,
            status: event.target.checked
          }
        });
        if (resolve) {
          resolve(pubApprove);
        } else {
          reject(pubApprove);
        }
      })
        .then(response => {
          if (response) {
            this.toastAlert(this.$t("alert.edit"));
          }
        })
        .catch(err => {
          this.errorAlert(err);
        });
    }
  },
  mounted() {
    this.listTSS();
    this.listGroup();
  }
};
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: #2aa952;
}

input:not(checked) + .slider {
  background-color: #ff0000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2aa952;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

hr.dw-divider {
  border-top: 1px solid #909090;
}
</style>
